.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
  z-index: 1200;
}

.toast p {
  margin: 0;
  font-size: 16px;
}
