/* Add this CSS in a separate CSS file, e.g., signIn.css */

.signIn-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../Images/Login.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .signIn-form {
    width: 525px;
    margin: 100px;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 24px;
    background-color: #fff;
  }
  
  .signIn-password {
    width: 525px;
    margin: 100px;
    padding: 40px;
    border: 1px solid #ccc;
    border-radius: 24px;
    background-color: #fff;
  }

  .signIn-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .signIn-logo > img {
    margin-bottom: 20px;
  }
  
  .signIn-form > form{
    display: flex;
    flex-direction: column;
  }

  .signIn-password > form{
    display: flex;
    flex-direction: column;
  }
  
  .signIn-form form>div{
    margin-bottom: 16px;
  }

  .signIn-password form>div{
    margin-bottom: 8px;
  }
  
  .signIn-form input {
    width: 100%;
    padding: 8px;
    color: #919191;   
    background-color: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
  }

  .signIn-password input {
    width: 100%;
    padding: 8px;
    color: #919191;   
    background-color: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
  }
  
  .signIn-form .inputError{
    border: 1px solid #dc3545;
  }

  .signIn-password .inputError{
    border: 1px solid #dc3545;
  }
  
  .signIn-form form>.loginLink{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .signIn-password form>.loginLink{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-top: 4px;
    margin-bottom: 0;
  }
  
  .loginLink > a{
    color: #1892E6;
    font-size: 16px;
    line-height: 21px;
  }
  
  .error{
    color: #dc3545;
    font-size: 12px;
    padding: 4px 2px 0;
  }
  

.forgetPassword{
  color: #1892E6;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 4px;
}

@media only screen and (max-width: 768px) {
  .signIn-form{
    width: 880px;
    padding: 24px;
    margin: 32px;
  }
  .signIn-password{
    width: 880px;
    padding: 24px;
    margin: 32px;
  }
}
@media only screen and (max-width: 576px) {
  .signIn-form{
    margin: 16px;
  }
  .signIn-password{
    margin: 16px;
  }
}