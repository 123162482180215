.blogPost {
  font-family: Gilroy;
  display: grid;
  grid-template-columns: max-content 1fr;
  padding: .5em;
  @apply border-b border-b-slate-400 mb-2;
}
.blogPost * {
  font-family: Gilroy;
  font-size: revert;
  font-weight: revert;
}
.postMeta {
  margin-right: 1em;
}
a.blogTag {
  @apply m-1 p-1 ml-0 pl-0 text-xs hover:text-orange-500;
}
