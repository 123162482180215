ul {
    list-style-type: " - ";
}
.raw-html, .raw-html * {
    font-family: Gilroy;
    font-size: revert;
    font-weight: revert;
    color: unset;
    margin: revert;
    padding: revert;
    list-style: revert;
}
.raw-html .clear {
    clear: both;
}
.raw-html div,.raw-html p {
    padding-bottom: 1em;
}
.raw-html h1 {
    color: chocolate;
}
.raw-html .orange {
    color: chocolate;
}
.raw-html img {
    display: inline;
    /* padding: 1em; */
}
.raw-html img.left,
.raw-html iframe.left {
    float: left;
    margin-right: 1em;
}
.raw-html img.right, 
.raw-html iframe.right{
    float: right;
    margin-left: 1em;
}
.raw-html a {
    text-decoration: revert;
    color: chocolate;
}
.raw-html iframe {
    display: inline;
}
@media (min-width: 992px)  {
    .raw-html img {
        max-width: 50%;
    }
}
@media (max-width: 776px)  {
    .raw-html img,
    .raw-html iframe {
        max-width: 100%;
        margin-bottom: .5em;
        margin-top: 0;
    }
}