.searchContainers {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 8fr 4fr;
}
.searchMobContainers{
    display: none;
}
.HeroSectionContainer{
    /* height: 100vh; */
    width: 98.75vw;
}
.HeroSectionContainer> video{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.signInContent{
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
}
.profileDropdown{
    background:linear-gradient(90deg, #ED1C24 0%, #FCAB2A 100%) 0% 0% no-repeat;
    right: -10px;
}
.showProfile {
    position: relative;
    width: 200px;
    height: auto;
    left: -120px;
    background: #ffffff;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px;
    padding-left: 10px;
}
@media only screen and (max-width: 1600px){
    .searchContainers {
        padding: 32px 220px 47px;    
    }
}
@media only screen and (max-width: 1400px){
    .searchContainers {
        padding: 32px 60px 47px;    
    }
}
@media only screen and (max-width: 768px){
    .searchContainers {
        display: none;
    }
    .searchMobContainers{
        display: flex;
    }
    .HeroSectionContainer{
        height: unset;
    }
    .HeroSectionContainer> video{
        object-fit: contain;
    }
}