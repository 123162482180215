.flash {
    animation: flash .5s ease-out 1s 1;
}
@keyframes flash {
    0% {
        box-shadow: 0 0 0 transparent;
    }
    70% {
        box-shadow: 0 0 40px rgba(34, 120, 34, 1);
    }
    90% {
        box-shadow: 0 0 20px rgba(34, 120, 34, 0.5);
    }
    100% {
        box-shadow: 0 0 0 transparent;
    }
}