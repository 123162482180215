ul.paging {
  list-style-type: none;
  font-family: Gilroy;
  font-weight: bolder;
}
ul.paging > li {
  display: inline;
  margin: 0;
  padding: 0 .3em;
  background-color: beige;
  border: 1px solid #00000010;
  color: chocolate;
}
ul.paging > li.disabled, ul.paging > li.disabled > a {
  font-weight: normal;
  color: gray;
  cursor: default;
}
