.profileContentContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    flex-wrap: nowrap;
}
.form-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 40px;
}
.form-container> div{
    margin-bottom: 20px;
}
  
.profile-form input {
    width: 100%;
    padding: 14px 15px;
    color: #919191;   
    background-color: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    margin-top: 8px;
}
.profile-form select {
    width: 100%;
    padding: 18.45px 0 18.45px 15px;
    color: #919191;
    background-color: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    margin-top: 8px;
}
.profile-form{
    margin-bottom: 18px;
}
.nicknameContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    gap: 8px;
}
.nicknameContainer > label{
    display: flex;
    width: auto;
    align-items: center;
    gap: 8px;
}
.nicknameContainer > label > input{
    margin-top: unset;
}
.nicknameContainer > label > span{
    white-space: nowrap;
}
@media only screen and (max-width:1200px) {
    .profileContentContainer{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 576px) {
    .form-container {
        grid-template-columns: repeat(1,1fr);
        gap: 16px;
    }
    .form-container> div{
        margin-bottom: unset;
    }
}
