.radio-container {
  display: flex;
  align-items: center;
}

.radio-label {
  margin-right: 10px;
}

.radio-input {
  width: 20px;
  height: 20px;
  border: 1.5px solid var(--descriptionText); 
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  accent-color: var(--feedbackColor);
}
