.footer{
    padding:51px 105px 53px 100px ;
/* UI Properties */
border: 2px solid #000000;
border-radius: 25px;
opacity: 1;
margin-top: 48px;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: nowrap;
gap: 40px;
}
@media only screen and (max-width: 992px){
    .footer{
        flex-wrap: wrap;
        padding: 51px 50px 53px;
        justify-content: center;
    }
}

.static-text, .static-text * {
    font-family: Gilroy;
    font-size: revert;
    font-weight: revert;
    margin: revert;
    padding: revert;
    list-style: revert;
    text-indent: 2em;
    color: rgb(131, 131, 131);
  }
  .static-text a {
    color: chocolate;
    text-decoration: underline;
  }