/* Add this CSS in a separate CSS file, e.g., Signup.css */

.signup-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../Images/Login.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.signup-form {
  width: 525px;
  margin: 100px;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 24px;
  background-color: #fff;
}

.signup-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 32px;
}

.signup-logo > img {
  margin-bottom: 20px;
}

.signup-form > form{
  display: flex;
  flex-direction: column;
}

.name-container{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 16px;
}

.info-container{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  column-gap: 16px;
}

.info-container > div:nth-child(1) , .info-container > div:nth-child(2){
  margin-bottom: 16px ;
}

.signup-form form>div{
  margin-bottom: 16px;
}

.signup-form input {
  width: 100%;
  padding: 8px;
  color: #919191;
  background-color: #F5F5F5;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
}

.signup-form .inputError{
  border: 1px solid #dc3545;
}

.signup-form select {
  width: 100%;
  padding: 13px 0 13px 6px;
  color: #919191;
  background-color: #F5F5F5;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
}


.signup-form form>.loginLink{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin-top: 4px;
  margin-bottom: 0;
}

.loginLink > a{
  color: #1892E6;
  font-size: 16px;
  line-height: 21px;
}

.error{
  color: #dc3545;
  font-size: 12px;
  padding: 4px 2px 0;
}

@media only screen and (max-width: 768px) {
  .signup-form{
    width: 880px;
    padding: 24px;
    margin: 32px;
  }
}
@media only screen and (max-width: 576px) {
  .signup-form{
    margin: 16px;
  }
}