.listSection {
    padding: 29px 33px;
    /* UI Properties */
    background: transparent linear-gradient(90deg, #ED1C24 0%, #FFCD22 100%) 0% 0% no-repeat padding-box;
    border-radius: 52px;
    display: flex;
    gap:40px;
    flex-wrap: nowrap;
    }
.listSectionImg{
    display: flex;
}
.listSectionItems{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}
.dropdownContainer{
    position: relative;
    padding-left: 11rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
@media only screen and (max-width: 992px){
    .listSection{
        flex-wrap: wrap;
    }
    .listSectionImg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .listSectionItems{
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .dropdownContainer{
        padding-left: unset;
    }
}