.offers-list {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
    background-size: contain;
    padding: 2em 0 0 0;
    margin: 0;
}
.logo-area {
    padding: 2em 2em 0 0;
    display: inline-flex;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}
.fund-page {
    margin-bottom: 1em;
}
@media (max-width: 519px) {
    .offers-list {
        grid-template-columns: 1fr;
    }
    .logo-area {
        display: block;
    }
    .fund-page {
        display: flex;
        flex-direction: column-reverse;
    }
    .offer-buttons {
        flex-direction: column;
    }
}
@media (max-width: 829px) and (min-width: 520px) {
    .offers-list {
        grid-template-columns: 1fr 1fr;
    }
}