.mainPaddedContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-size: contain;
    overflow-y: auto;
    height: 100vh;
}
.detailsSection {
    padding: 0px 220px 271px ;
}
.aboutUsSection {
    display: flex;
    width: 100%;
    align-items: center;
    padding:40px 0px 60px;
    gap:46px;
    flex-wrap: nowrap;
}
.gradientBorder{
    border: 18px solid #EE26244D;
    border-radius: 66px;
    box-shadow: 20px 22px 42px #EE262480;
    width: 50%; 
}
.arrowIconTransition {
    transition:  margin-left 0.4s ease-in-out;
}
.aboutUsTextContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 50%;
}
.tooltipContent{
    opacity: 0.6;
}
.tooltipContent:hover{
    opacity: 1;
}
.VerticalImageContainer{
    color: #fff;
    opacity: 0.6;
}
.VerticalImageContainer:hover{
    opacity: 1;
}
.aboutUsContainer{
    position: absolute;
    width: 100%;
    padding: 40px 220px 47px;
    background-size: contain;
    overflow-y: auto;
    height: 100vh;
}
.slick-track{
    display: flex !important;
    margin-bottom: 5px;
    gap:20px;
}
.slick-initialized .slick-slide{
    display: flex !important;
}
.videoTitleContainer h4{
    right: 4%;
}
.blink {
    animation: blink-animation 1.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@media only screen and (max-width: 1800px){
    .videoTitleContainer h4{
        right: 4%;
    }
}
@media only screen and (max-width: 1600px){
    .aboutUsContainer {
        padding: 40px 220px 47px;
    }
    .detailsSection {
        padding: 0px 140px 271px;
    }
}
@media only screen and (max-width: 1400px){
    .aboutUsSection {
        flex-wrap: nowrap;
    }
    .detailsSection {
        padding: 0px 60px 271px ;
    }
    .aboutUsContainer {
        padding: 40px 60px 47px;
    }
    .videoTitleContainer h4{
        right: 4%;
    }
}
@media only screen and (max-width: 1200px) {
    .videoTitleContainer h4{
        right: 6%;
    }
}
@media only screen and (max-width: 992px){
    .aboutUsSection {
        flex-wrap: wrap;
    }
    .gradientBorder{
        width: auto;
    }
    .aboutUsTextContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: auto;
    }
}
@media only screen and (max-width: 768px){
    .aboutUsSection {
        flex-wrap: wrap;
    }
    .detailsSection {
        padding: 0px 16px 271px ;
    }
    .aboutUsContainer {
        padding: 40px 16px 47px;
    }
    .videoTitleContainer h4{
        right: 6%;
    }
}
.down-text {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    /* border-radius: 5px; */
    cursor: pointer;
  }
