.custom-input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.3s, background-color 0.3s;
  background: var(--fadedBg) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--formBorder);
  border-radius: 10px;
  font: normal normal normal 12px/24px Gilroy;
  letter-spacing: 0px;
  color: var(--descriptionText);
  outline: none;
}