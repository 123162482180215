.modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    z-index: 1000;
    background-color: var(--white);
    border-radius: 38px;
    width: 75%;
    max-height: 90%;
    overflow-y: auto;
}
.confirmModalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    z-index: 1000;
    background-color: var(--white);
    border-radius: 38px;
    width: 50%;
    max-height: 90%;
    overflow-y: auto;
}
.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: var(--overlay);
    z-index: 1000;
}
.crossIcon {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 100000;
    cursor: pointer;
}