.customHeadingText {
    color: white;
    font-size: 100px;
    font-family: 'GilroyBold';
}
.customSubHeadingText {
    color: white;
    font-size: 30px;
}
.gradient-text {
    background-image:  linear-gradient(88deg, #ED1C24 42%, #FFCD22 55%);/* Define your gradient colors */
    background-clip: text; /* Clip the background to the text */
    -webkit-background-clip: text; /* For Safari */
    color: transparent; /* Make the text transparent */
  }
  
span.link {
    color: var(--feedbackColor);
    cursor: pointer;
}