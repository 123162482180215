@font-face {
  font-family: 'Gilroy';
  src: url('../src//assets/fontFamilies/Gilroy-Regular.ttf') format('opentype'); /* Adjust the path and format as needed */
  /* You can also include font-weight and font-style variations if needed */
}

@font-face {
  font-family: 'GilroyBold';
  src: url('../src//assets/fontFamilies/Gilroy-Bold.ttf') format('opentype'); /* Adjust the path and format as needed */
  /* You can also include font-weight and font-style variations if needed */
}

@font-face {
  font-family: 'GilroyMedium';
  src: url('../src//assets/fontFamilies/Gilroy-Medium.ttf') format('opentype'); /* Adjust the path and format as needed */
  /* You can also include font-weight and font-style variations if needed */
}


/* @media (max-width: 1024px) {
  .carouselContainer .slick-prev,
  .carouselContainer .slick-next {
    display: none !important;
  }
}

.custom-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
} */

:root {
  --highlighted-text: #FFFFFF;
  --not-highlighted-text: black;
  --sub-heading: lightgray;
  --white:white;
  --black:black;
  --faded:rgba(255, 255, 255, 0.5);
  --descriptionText:#919191;
  --danger:#EE2624;
  --info : #FEC722;
  --fadedBorder:#919191;
  --feedbackColor: #1592E6;
  --fadedBg: #F5F5F5;
  --formBorder: #707070;
  --overlay:rgba(0,0,0,.4)
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .slick-slide {
  width: 400px !important;
  margin-right: 24px;
  } */

.videoTitleContainer h4 {
  text-shadow: 0 0 3px #ffffff;
  background-color: #ffffffd7;
  box-shadow: 0px 0px 20px 10px #ffffff;
  border-radius: .5em;
}

.videoTitleContainer > img {
  min-height: 200px;
}

.paper_background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}
