.offer {
    box-shadow: 2px 2px 10px black;
    margin: .7em;
    padding: 1em;
    border-radius: 1em;
    background-color: #eee;
    display: grid;
    grid-template-rows: 1fr;
}
.offer.selected {
    filter: drop-shadow(2px 4px 16px chocolate);
}
.offer-title {
    grid-column: span 2;
}
.offer-icon {
    width: 12vh;
    height: auto;
    aspect-ratio: 1/1;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;
    padding: 1em;
    grid-column: 1;
}
.offer-description {
    grid-column: 2;
}
.offer-form {
    grid-row: 3;
    grid-column: span 2;
    position: relative;
}
.offer-price {
    display: inline-block;
    font-size: 1.5em;
    padding: .35em .35em 0 0;
    font-weight: bolder;
}
input.offer-quant {
    max-width: 3em;
    font-size: larger;
    text-align: center;
}
input.offer-quant.inputError {
    filter: drop-shadow(red);
}
.offer-buttons {
    position: relative;
    right: 0;
    bottom: 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}
input.submitButton:hover {
    transform: translateY(1px);
}
input[name="select"][type="button"] {
    position: absolute;
    right: 0;
    top: .5em;
}
.offer-submit {
    width: 100%;
}
.offer-submit-container {
    margin: 1em .2em 0 .2em;
}
.offer-submit-container.preferred::after {
    content: "* Preferred";
    position: absolute;
    left: 30%;
    text-align: right;
    color: chocolate;
    font-size: x-small;
    font-weight: bolder;
}
.offer-submit.moderate {
    filter: sepia(.7);
}
.offer-form div.error {
    position: absolute;
    margin-top: -.5em;
}