/* CircularLoader.css */
.circular-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1); /* Add a semi-transparent background overlay */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1999; /* Ensure the loader is on top of other content */
  }
  
  .spinner {
    border: 6px solid var(--white); /* Light gray border */
    border-top: 6px solid var(--feedbackColor); /* Blue border for animation */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Add a spinning animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  