.problemDescriptionInput {
  padding: 10px;
  border: 0.5px solid var(--formBorder);
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  height: 120px;
  display: block;
  margin: 10px 0;
  resize: none;
  outline: none;
  background: var(--fadedBg) 0% 0% no-repeat padding-box;
  font: normal normal normal 12px/24px Gilroy;
  color: var(--descriptionText);
}