
:root {
    --danger-color: #ff0000;
  }
.mainFrameImageContainer {
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 2px solid var(--danger-color);
  border-bottom-color: var(--danger-color);
}