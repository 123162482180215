.feedbackFormContainer {
    border-radius: 38px;
    position: relative;
    display: flex;
    flex-direction: column;
    
}

.feedbackChaptersContainer {
    border-radius: 25px;
    padding: 22px 27px;
    border: 1px solid var(--formBorder);
    display: flex;
    flex-direction: column;
    width: 100%;
}


  