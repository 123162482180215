.blogPage, .aboutUsContainer.blog {
  padding-top: 0;
  height: auto;
}
.postForm * {
  @apply m-2;
}
input.is-invalid, textarea.is-invalid {
  @apply border-pink-500 text-pink-600;
}
